import Axios, { CancelToken } from 'axios'
import {getCaptionFontMapUrl, getPlatePriceUrl} from '../utils/ApiUrls';
import { PlatePricingModel } from './Types';
import { CaptionPosition } from '@models/ProductModels';
import { MessagePlateLocations } from '@utils/Constant';

const getCaptionFontMap = async(apiUrl:string, cancelToken?: CancelToken) => {
    const url = getCaptionFontMapUrl(apiUrl);
    try{
        const result = await Axios.get(url,{cancelToken: cancelToken});
        return result.data.Data;
    }catch (error) {
        if (Axios.isCancel(error)) {
            console.log("Caption Font Map Request canceled", error.message);
            throw new Error("Cancelled");
        }
    }
}
const trimCaptionMessage = (plateDesignCode: number, fontMap: any, captionPosition:string | undefined, input: string | undefined, captionMaxLength?: number) => {
    return input !== undefined ? captionFns(captionMaxLength).trim(plateDesignCode, fontMap, captionPosition, input) : '';
}
const calculateCavityWidthRemaining = (plateDesignCode:number, plateFont:any, captionPosition:CaptionPosition | undefined,caption:string | undefined, captionMaxLength?: number) =>{
    const characterMap = getCharacterMap(plateDesignCode,plateFont,captionPosition);
    return captionFns(captionMaxLength).remaining(characterMap, caption);
}
const getCharacterMap = (plateDesignCode: number, plateFont: any | undefined , caption: CaptionPosition | undefined) =>{
    if(!plateFont) return undefined;
    if(plateDesignCode == 145){
        return plateFont.CharacterMapSmall;
    }else if(caption && caption.value ===  MessagePlateLocations.TopAndBottom)
    {
        return plateFont.CharacterMapSmall;
    }
    else{
        return plateFont.CharacterMapLarge;
    }
}

const getPlatePrice = async(apiUrl: string, transactionType: number, frontPlateDesignId: number, frontPlateSize: number,backPlateDesignId: number, backPlateSize: number, vehicleTypeId: number, combination: string, cancelToken?: CancelToken) =>{
    const model = {
        transactionType: transactionType,
        plateDesignId: frontPlateDesignId,
        plateSize: frontPlateSize,
        dualPlateDesignId: backPlateDesignId,
        dualPlateSizeId: backPlateSize,
        vehicleTypeId: vehicleTypeId,
        combination: combination
    }
    const url = getPlatePriceUrl(apiUrl, model);
    try{
        const result = await Axios.get(url,{cancelToken: cancelToken});
        return result.data.Data;
    }catch (error) {
        if (Axios.isCancel(error)) {
            console.log("Plate Price Request canceled", error.message);
            throw new Error("Cancelled");
        }
    }
}

function captionFns(captionMaxLength?: number) {

    var cavityWidth = captionMaxLength !== undefined ? captionMaxLength : 320; // Cavity width to 338px to fit TOWERCRANESNZ
    /* eslint-disable no-useless-escape */
    var whitelist = "[^ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789~'!@#$%^&*:;_ \\\(\)\-\+\=\.\/\?\-\{\}\,\"\]\[\>\<]";
    /* eslint-enable no-useless-escape */

    return {
        validate: function (plateDesignCode: number, map: any, newValue:string, oldValue:string) {

            if (this.remaining(map, newValue) < 0) {
                if (this.remaining(map, oldValue) >= 0) {
                    return oldValue;
                } else {
                    return this.trim(plateDesignCode, map, undefined, newValue);
                }
            }

            return newValue;
        },
        getWidth: function (map : any | undefined, caption: string | undefined) {

            if (!map || !caption || caption.length === 0) return 0;

            var width = 0.00;

            for (var i = 0; i <= caption.length - 1; i++) {
                var char:any = caption[i].toUpperCase();
                width += (map[char]) ? map[char] : 27.5;
            }

            return Math.ceil(width);

        },
        trim: function (plateDesignCode: number, map:any, captionPosition:string | undefined, caption:string) {

            if (!map || !caption) return "";

            let maxCharacterLength = 43;
            let charMap = map.CharacterMapLarge;
            if(captionPosition === 'top_and_bottom' || plateDesignCode == 145){
                charMap = map.CharacterMapSmall;
                maxCharacterLength = 69;
            }


            if (caption.length > maxCharacterLength) caption = caption.substring(0, maxCharacterLength);

            var currentLength = 0;
            var lastIndex = 0;

            for (var i = 0; i <= caption.length; i++) {
                if(currentLength + charMap[caption[i]] <= cavityWidth){
                    currentLength += charMap[caption[i]];
                    lastIndex ++;
                }
                else {
                    break;
                }
            }

            caption = caption.substring(0, lastIndex);

            while(caption[0] === ' '){
                caption = caption.length === 1 ? '' : caption.slice(1, caption.length)
            }
            while(caption[caption.length - 1] === ' ' && caption[caption.length - 2] === ' '){
                caption = caption.slice(0, -1);
            }

            return caption;
        },
        remaining: function (map:any | undefined, caption:string | undefined) {
            return cavityWidth - this.getWidth(map, caption);
        },
        stripChars: function (caption:string) {

            if (!caption || !whitelist) return "";

            var newCaption = "";

            for (var i = 0; i <= caption.length - 1; i++) {
                var compareChar = caption[i].toUpperCase();
                if (whitelist.indexOf(compareChar) > -1) newCaption += compareChar;
            }

            return newCaption;

        },
        makeSafe: function (caption:string) {

            if (!caption) return "";
            caption = caption.replace(/%/g, "%25").replace(/#/g, "%23").replace(/&/g, "%26").replace(/\+/g, "%2B");

            return caption;
        }
    }

}


export const PlateService = {
    getCaptionFontMap: getCaptionFontMap,
    calculateCavityWidthRemaining: calculateCavityWidthRemaining,
    getPlatePrice: getPlatePrice,
    trimCaptionMessage: trimCaptionMessage
}