import { GridRow, Segment, Icon, GridColumn } from "semantic-ui-react"
import styled from "@emotion/styled"
import theme from "@styles/theme"
import { H4, H6 } from "@styles/Global.styles"
import YouTube from "react-youtube"
import mq from '@styles/mq'

export const PlateDesignPricing = styled.div`
    float: right;
`
export const PrcingFrom = styled.span`
    ${mq({
        fontSize:['12px','12px'],
        lineHeight:['16px','16px']
    })}
    color: #B7B7B7;
    font-size: Medium;
    letter-spacing: -0.24px;
`
export const PlatePriceTag = styled(H6)`
    float: right;
    margin-top: 0 !important;
    padding-left: 2px;
`

export const PlateStoryVideo = styled(YouTube)`
  width: 100%;
  ${mq({
    height: ['40vw','390px','500px','635px']
  })}
`

export const PlayVideoButtonIcon = styled(Icon)`
  color: ${theme.brand.colors.white};

  ${mq({
    fontSize:['20px !important','30px !important', '41px !important'],
  })}
`

export const PlayVideoButton = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: ${theme.brand.colors.green};
  cursor: pointer;
  border: none;

  ${mq({
    width:['50px','75px', '100px'],
    height:['50px','75px', '100px'],
    borderRadius:['50px','75px', '100px'],
    padding: ['0 0 0 10px', '0 0 0 10px', '0 0 5px 10px']
  })}
`

export const VideoContainer = styled.div`
  position: relative;
  ${mq({
    margin:['25px 0 25px 0','30px 0 25px 0', '50px 0 0 0'],
    border: [`10px solid ${theme.brand.colors.green}`,`10px solid ${theme.brand.colors.green}`,`30px solid ${theme.brand.colors.green}`],
    borderRadius: ['10px','10px','20px']
  })}
`

export const VideoContainerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
      -ms-transition: all 0.7s ease-out;
        -o-transition: all 0.7s ease-out;
          transition: all 0.7s ease-out;
  opacity: 1;

  &.videoPlaying {
    opacity: 0;
    top: 1000px;
  }
`

export const VideoControlsContainer = styled(GridColumn)`
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%) !important;
`

export const VideoControlsContainerHeader = styled(H4)`
  color: ${theme.brand.colors.white};
  margin-top: 5px;
`

export const FeaturedImage = styled.img`
  width: 100%;
  margin-bottom: 30px !important;
`

export const PlateImage = styled.img`
  margin: 20px 0 !important;
  width: 100%;
`

export const PlateImageContainer = styled.div`
  border-top: 3px solid grey;
  margin-top: 10px;
`

export const PlateStorySegment = styled(Segment)`
  &&&&{
    background-color: white;
    border: none;
    box-shadow: none;
    margin: 0 !important;

    ${mq({
      padding:['50px 0 100px 0 !important','100px 0 !important', '100px 0 !important'],
    })}
  }

`

export const PlateStoryRow = styled(GridRow)`
  padding-top: 0 !important;
  padding-bottom: 0 !important
`