import { KenticoPlateStory, KenticoPlateStorySummary } from "@models/PlateStories/KenticoPlateStory"
import { PlateStory, PlateStorySummary } from "@models/PlateStories/PlateStory"
import { PlateStoryPlate } from "@models/PlateStories/PlateStoryPlate"
import { buildPlateImageUrl, getPlateSize, getTextColor, getKenticoCode, mapFirstKenticoImage } from "@utils/Helpers"

export const mapPlateStorySummaryList = (plateStories: KenticoPlateStorySummary[]): PlateStorySummary[] =>{
    return plateStories.map((story)=>{
        return mapPlateStorySummary(story);
    })
}

const mapPlateStorySummary = (plateStory: KenticoPlateStorySummary): PlateStorySummary =>{
    const summary: PlateStorySummary = {
        bannerFeaturedImage: plateStory.elements.landing_page_featured_image.value.length > 0 ? plateStory.elements.landing_page_featured_image.value[0] : undefined,
        landingFeaturedImage: plateStory.elements.main_plate_story_landing_page_image.value.length > 0 ? plateStory.elements.main_plate_story_landing_page_image.value[0] : undefined,
        pageFeaturedImage: mapFirstKenticoImage(plateStory.elements.featured_image.value),
        title: plateStory.system.name,
        categories: plateStory.elements.plate_story_category.value.map((category) => {return category.name}),
        isFeatured: plateStory.elements.is_featured.value.length > 0 ? plateStory.elements.is_featured.value[0].codename === 'is_featured' : false,
        url: plateStory.elements.url.value
    }
    return summary;
}

export const mapPlateStories = (apiUrl: string, plateStories: KenticoPlateStory[]):PlateStory[]  => {
    return plateStories.map((plateStory) => {
        return mapPlateStory(apiUrl,plateStory)
    });
} 

export const mapPlateStory = (apiUrl: string, plateStory: KenticoPlateStory):PlateStory  => {
    return {
        title: plateStory.system.name,
        categories: plateStory.elements.plate_story_category.value.map((category) => {return category.name}),
        isFeatured: plateStory.elements.is_featured.value.length > 0 ? plateStory.elements.is_featured.value[0].codename === 'is_featured' : false,
        url: plateStory.elements.url.value,
        body: plateStory.elements.body.value,
        featuredImage: mapFirstKenticoImage(plateStory.elements.featured_image.value),
        plate: plateStory.elements.plate !== undefined ? mapPlateStoryPlate(apiUrl,plateStory) : undefined,
        sliderImages: {
            images: plateStory.elements.image_gallery.value
        },
        summary: plateStory.elements.summary.value,
        videoId: plateStory.elements.youtube_video_url.value
    }
} 

export const mapPlateStoryPlate = (apiUrl: string,plateStory: KenticoPlateStory):PlateStoryPlate  => {
    let kenticoPlate = plateStory.elements.plate.linked_items[0].elements;
    let kenticoPlateDesign = kenticoPlate.plate_design.linked_items[0].elements;

    return {
        plateDesignName: kenticoPlate.plate_design.linked_items[0].system.name,
        platePrice: kenticoPlateDesign.base_price.value,
        plateName: kenticoPlate.plate_title.value,
        plateCombination: kenticoPlate.plate_combination.value,
        plateDesignId: parseInt(getKenticoCode(kenticoPlateDesign.kpi_plate_design_code.value[0].codename)),
        plateSizeId: getPlateSize(kenticoPlateDesign.plate_size.value[0].name, kenticoPlateDesign.plate_size.value[0].codename).id,
        plateImageUrl: buildPlateImageUrl(
            apiUrl,
            {
            plateDesignCode: parseInt(getKenticoCode(kenticoPlateDesign.kpi_plate_design_code.value[0].codename)),
            plateSize: getPlateSize(kenticoPlateDesign.plate_size.value[0].name, kenticoPlateDesign.plate_size.value[0].codename),
            textColor: getTextColor(kenticoPlateDesign.kpi_plate_foreground_color.value[0])
        }, kenticoPlate.plate_combination.value, 1000)
    }
} 