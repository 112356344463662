import React from "react"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from "@components/SEO"
import { Banner, mapToPageHeader } from "@components/Banner"
import { mapPlateStory } from "@mappers/PlateStories/mapper"
import {
  ImageInformationList,
  mapToInfoBannerContainer,
} from "@components/ImageInformationList"
import {
  mapToKiwiVIPVisible,
  SignupBannerWrapper,
} from "@components/SignupBanner"
import { PlateStory } from "@components/PlateStory"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { graphql } from "gatsby"
import {
  QuickCombinationFinder,
} from "@components/CombinationFinder"
import theme from "@styles/theme"

declare global {
  namespace YT {
    interface Player {}
  }
}

interface Props {
  data: any
  summary: string
  body: string
  featuredImageUrl: string
  imageGalleryUrls: string[]
  isFeatured: boolean
  videoUrl: string
  categories: string[]
}

interface State {
  videoPlaying: boolean
  youtubeVideo: any
}

class PlateStoryTemplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      videoPlaying: false,
      youtubeVideo: null,
    }
  }

  render() {
    const { elements } = this.props.data.kontentItemPlateStoryPage
    const metadata = mapToPageHeader(elements)
    const apiUrl = this.props.data.site.siteMetadata.apiUrl
    const seoData = mapToSEO(elements)
    const infoBanner = mapToInfoBannerContainer(
      elements.info_banner.linked_items[0].elements
    )
    // const imageLink = mapToImageLinkBanner(elements.image_link_banner.linked_items[0].elements)
    const kiwiVip = mapToKiwiVIPVisible(elements)
    const plateStoryData = mapPlateStory(
      apiUrl,
      this.props.data.kontentItemPlateStoryPage
    )
    const reCaptchaSiteKey = this.props.data.site.siteMetadata.reCaptchaSiteKey;
    const reCaptchaSiteKeyV2 = this.props.data.site.siteMetadata.reCaptchaSiteKeyV2;

    return (
      <Layout version="standard">
        <SEO {...seoData} />
        <Banner {...metadata} />
        <PlateStory plateStory={plateStoryData} apiUrl={apiUrl} />

        {/* <ImageButtonBanner {...imageLink} /> */}
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 60,
              bottom: 60,
            },
            desktop: {
              top: 80,
              bottom: 80,
            },
          }}
          backgroundColor={theme.brand.colors.white}
        >
          <QuickCombinationFinder title="Find your Combination" reCaptchaSiteKey={reCaptchaSiteKey} apiUrl={apiUrl} darkTheme={false}
                reCaptchaSiteKeyV2={reCaptchaSiteKeyV2}/>
        </GenericBannerContainer>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 60,
              bottom: 80,
            },
            desktop: {
              top: 80,
              bottom: 100,
            },
          }}
          backgroundColor={infoBanner.backgroundColor}
        >
          <ImageInformationList
            listItems={infoBanner.listItems}
            backgroundColor={infoBanner.backgroundColor}
            columns={4}
            horizontalLayout={false}
            titleOverlayImage={false}
            titleColor="white"
            textColor="white"
          />
        </GenericBannerContainer>

        {kiwiVip.visible && <SignupBannerWrapper version={kiwiVip.version} />}
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        apiUrl
        reCaptchaSiteKey
        reCaptchaSiteKeyV2
      }
    }
    kontentItemPlateStoryPage(fields: { slug: { eq: $slug } }) {
      system {
        name
      }
      elements {
        plate_story_category {
          value {
            codename
            name
          }
        }
        featured_image {
          value {
            url
            description
          }
        }
        body {
          value
        }
        general_page_content__header_image {
          value {
            url
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            codename
            name
          }
        }
        is_featured {
          value {
            name
            codename
          }
        }
        plate_story_category {
          value {
            codename
            name
          }
        }
        image_gallery {
          value {
            url
            description
          }
        }
        image_link_banner {
          linked_items {
            ... on KontentItemImageLinkBanner {
              elements {
                button__button_color_theme {
                  value {
                    codename
                    name
                  }
                }
                button__button_type {
                  value {
                    codename
                    name
                  }
                }
                button__text {
                  value
                }
                button__url_slug {
                  value
                }
                image {
                  value {
                    url
                  }
                }
                title {
                  value
                }
              }
            }
          }
        }
        info_banner {
          linked_items {
            ... on KontentItemInfoBannerContainer {
              elements {
                background_colors {
                  value {
                    codename
                    name
                  }
                }
                items {
                  linked_items {
                    ... on KontentItemInfoBannerItem {
                      elements {
                        description {
                          value
                        }
                        icon {
                          value {
                            url
                          }
                        }
                        linktext {
                          value
                        }
                        linkurl {
                          value
                        }
                        title {
                          value
                        }
                      }
                    }
                  }
                }
                text_alignment {
                  value {
                    codename
                    name
                  }
                }
                text_colors {
                  value {
                    codename
                    name
                  }
                }
              }
            }
          }
        }
        is_featured {
          value {
            codename
            name
          }
        }
        plate {
          linked_items {
            ... on KontentItemPlateStoryPlate {
              elements {
                plate_combination {
                  value
                }
                plate_design {
                  linked_items {
                    ... on KontentItemPlateDesign {
                      system {
                        name
                      }
                      elements {
                        name {
                          value
                        }
                        dealer_only_design {
                          value {
                            name
                            codename
                          }
                        }
                        disable_dual_sizing {
                          value {
                            codename
                            name
                          }
                        }
                        authorized_dealers {
                          linked_items {
                            ... on KontentItemAuthorizedDealers {
                              elements {
                                title {
                                  value
                                }
                                link_message {
                                  value
                                }
                                description {
                                  value
                                }
                                dealer_contacts {
                                  linked_items {
                                    ... on KontentItemDealerContact {
                                      elements {
                                        dealer_name {
                                          value
                                        }
                                        contact_name {
                                          value
                                        }
                                        phone {
                                          value
                                        }
                                        formatted_phone_number {
                                          value
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        base_price {
                          value
                        }
                        kpi_plate_design_code {
                          value {
                            codename
                            name
                          }
                        }
                        kpi_plate_foreground_color {
                          value {
                            codename
                            name
                          }
                        }
                        plate_size {
                          value {
                            codename
                            name
                          }
                        }
                      }
                    }
                  }
                }
                plate_title {
                  value
                }
              }
            }
          }
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        summary {
          value
        }
        url {
          value
        }
        youtube_video_url {
          value
        }
      }
    }
  }
`

export default PlateStoryTemplate
