import React from 'react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { ImageSliderModel } from '@models/ImageSlider/ImageSlider'
import { H2 } from '@styles/Global.styles'
import KpButton from '@elements/KpButton/KpButton'
import { Grid } from 'semantic-ui-react'
import { ImageSliderImage } from './ImageSlider.Styles'


interface ImageSliderProp {
  slidesToShow: number,
  showDots: boolean,
  images: ImageSliderModel
}

const ImageSlider = (props: ImageSliderProp) => {
    const {images} = props;
    var settings = {
      dots: props.showDots,
      infinite: true,
      autoplay: true,
      slidesToShow: props.slidesToShow,
      slidesToScroll: 1,
      className: 'react-slider',
      dotsClass: 'default-slick-dots',
      responsive:[
        {
          breakpoint: 1440,
          settings:{
            slidesToShow: props.slidesToShow < 3 ? props.slidesToShow : 3,
            slidesToScroll:2,
            infinite: true,
            dots: false,
            arrows: true
          }
        },
        {
          breakpoint: 1024,
          settings:{
            slidesToShow: props.slidesToShow < 2 ? props.slidesToShow : 2,
            slidesToScroll:1,
            infinite: true,
            dots: !props.showDots ? false : true,
            arrows: false
          }
        },
        {
          breakpoint: 650,
          settings:{
            slidesToShow: 1,
            slidesToScroll:1,
            infinite: true,
            dots: !props.showDots ? false : true,
            arrows: false
          }
        }
      ]
    };
    return (
      <Grid textAlign='center'>
        {
          images.heading &&
          <H2>{images.heading}</H2>
        }
        <Grid.Row>
          <Grid.Column width={16}>
            <Slider {...settings}>
              {
                images.images.map((data,index)=>{
                  return <ImageSliderImage fullWidth={props.slidesToShow < 2} key={index} alt={data.description ? data.description : 'Kiwiplates slider'} src={data.url}/>
                })
              }
            </Slider>
          </Grid.Column>
        </Grid.Row>
        {
          images.showButton && images.button &&
          <Grid.Column width={16} textAlign='center'>
            <KpButton id='ImageButton' buttonType={images.button.type} color={images.button.theme} link={images.button.url}>{images.button.text}</KpButton>
          </Grid.Column>
        }
      </Grid>
    );
  
}

export default ImageSlider