import React from "react"
import { Options } from 'react-youtube'
import { Grid, Container, Responsive } from "semantic-ui-react";
import { Paragraph1, Paragraph2, H5 } from "@styles/Global.styles";
import { ImageSlider } from "@components/ImageSlider";
import theme from "@styles/theme";
import { PlateStory } from "@models/PlateStories/PlateStory";
import {
  FeaturedImage,
  PlateImageContainer,
  PlateImage,
  PlateDesignPricing,
  PrcingFrom,
  PlatePriceTag,
  VideoContainer,
  VideoContainerOverlay,
  VideoControlsContainer,
  PlayVideoButton,
  PlayVideoButtonIcon,
  VideoControlsContainerHeader,
  PlateStoryVideo

} from './PlateStory.styles'
import { PlateService } from "@services/PlateService";
import { KpButton } from "@elements/index";
import { GenericBannerContainer } from "@components/GenericBannerContainer";

interface Props {
  plateStory: PlateStory
  apiUrl: string
}

interface State {
  platePrice: number,
  videoPlaying: boolean,
  youtubeVideo: any
}


class PlateStoryTemplate extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props)
    this.state = {
      platePrice: 0,
      videoPlaying: false,
      youtubeVideo: null
    }
  }

  getPlatePrice = () => {
    const { apiUrl, plateStory } = this.props;

    if (plateStory.plate !== undefined) {
      PlateService.getPlatePrice(apiUrl, 1, plateStory.plate.plateDesignId, plateStory.plate.plateSizeId, plateStory.plate.plateDesignId, plateStory.plate.plateSizeId, 1, plateStory.plate.plateCombination).then((response: any) => {
        if (response && response.TotalPriceIncGST > 0) {
          this.setState({
            platePrice: response.TotalPriceIncGST
          })
        }
      });
    }
  }

  render() {
    const { plateStory } = this.props;
    const { platePrice } = this.state;

    const videoPlayerOptions: Options = {
      height: '650',
      playerVars: {
        controls: 1,
        modestbranding: 1,
        showinfo: 0
      }
    }

    const playVideo = () => {
      this.state.youtubeVideo.playVideo();
      this.setState({
        videoPlaying: true
      })
    }

    const onVideoLoaded = (event: any) => {
      this.setState({
        youtubeVideo: event.target
      })
    }

    const videoContainer = <Grid.Column width={16}>
      <VideoContainer>
        <VideoContainerOverlay className={this.state.videoPlaying ? 'videoPlaying' : ''}>
          <Grid verticalAlign='middle' centered>
            <VideoControlsContainer textAlign='center'>
              <PlayVideoButton onClick={playVideo}>
                <PlayVideoButtonIcon name='play' />
              </PlayVideoButton>
              <VideoControlsContainerHeader>
                Watch Story
                  </VideoControlsContainerHeader>
            </VideoControlsContainer>
          </Grid>
        </VideoContainerOverlay>
        <PlateStoryVideo className='plate-story-video' onReady={onVideoLoaded} videoId={plateStory.videoId} opts={videoPlayerOptions} />
      </VideoContainer>
    </Grid.Column>

    return (
      <div>
        <GenericBannerContainer
          padding={{
            mobile: {
              top: 40,
              bottom: 40
            },
            desktop: {
              top: 60,
              bottom: 60
            }
          }}
          backgroundColor={theme.brand.colors.white}>
          <Container>
            <Responsive as={Grid} maxWidth={theme.responsive.tablet.maxWidth}>
              {
                plateStory.videoId && plateStory.videoId !== "" &&
                videoContainer
              }
            </Responsive>
            <Grid stackable reversed='mobile tablet vertically'>
              <Grid.Column width={16}>
                <Paragraph1>
                  <strong>
                    {plateStory.summary}
                  </strong>
                </Paragraph1>
                <Grid>
                  <Grid.Column mobile={16} tablet={8} computer={7}>
                    <Paragraph2 id="body" dangerouslySetInnerHTML={{ __html: plateStory.body }}>
                    </Paragraph2>
                    <Grid>
                      <Grid.Column width="16" textAlign='left'>
                        <KpButton id='create-button' buttonType='primary' link='/create/' color={theme.brand.colors.blue}>Create a Plate</KpButton>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={7} floated='right'>
                    <div>
                      <FeaturedImage alt={`plate story image: ${plateStory.featuredImage.description}`} src={plateStory.featuredImage.url} />
                    </div>
                    <div>
                      <div>
                        <H5>
                          <strong>
                            {!plateStory.plate ? '' : plateStory.plate.plateName}
                          </strong>
                        </H5>
                      </div>
                      <PlateImageContainer>
                        <PlateImage src={!plateStory.plate ? '' : plateStory.plate.plateImageUrl} alt="plate story plate image" />
                      </PlateImageContainer>
                      <div>
                        <Grid>
                          <Grid.Column floated='left' width={8}>
                            <Paragraph1>
                              <strong>
                                {!plateStory.plate ? '' : plateStory.plate.plateDesignName}
                              </strong>
                            </Paragraph1>
                          </Grid.Column>
                          <Grid.Column floated='right' width={8}>
                            <PlateDesignPricing>
                              <PrcingFrom>From</PrcingFrom>
                              <PlatePriceTag color={theme.brand.primary}>${platePrice > 0 ? platePrice : this.getPlatePrice()}</PlatePriceTag>
                            </PlateDesignPricing>
                          </Grid.Column>
                        </Grid>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid>
              </Grid.Column>

            </Grid>
          </Container>
        </GenericBannerContainer>
        <ImageSlider showDots={false} slidesToShow={1} images={plateStory.sliderImages}></ImageSlider>
        {
          plateStory.videoId && plateStory.videoId !== "" &&
          <Responsive minWidth={theme.responsive.desktop.minWidth}>
            <GenericBannerContainer
              padding={{
                mobile: {
                  top: 0,
                  bottom: 80
                },
                desktop: {
                  top: 0,
                  bottom: 100
                }
              }}
              backgroundColor='transparent'>
              <Container>
                <Responsive as={Grid}>
                  {videoContainer}
                </Responsive>
              </Container>
            </GenericBannerContainer>
          </Responsive>
        }
      </div>
    )
  }
}



export default PlateStoryTemplate